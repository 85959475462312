<template>
  <div :class="lotteryType">
    <b-card>
      <h3>{{ moment().locale($i18n.locale).format("LL") }}</h3>

      <b-row class="betting-form-container">
        <b-col lg="6">
          <b-form-group :label="$t('field.lotteryName')">
            <b-form-radio-group
              class="button-checkbox"
              v-model="data.lotteryId"
              name="lottery"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio
                :value="lottery.id"
                v-for="(lottery, index) in lotteries"
                :key="`lottery-${index}-${lottery.id}`"
                buttons
              >
                <span>
                  {{ $i18n.locale == "en" ? lottery.name : lottery.nameKh }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group :label="$t('field.lotteryTime')">
            <b-form-radio-group
              class="button-checkbox"
              v-model="data.lotteryTimeId"
              name="time"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio
                :value="time.id"
                v-for="time in lotteryTimes"
                :key="`time-${time.id}`"
                buttons
              >
                <span>
                  {{
                    moment(time.name, "HH:mm")
                      .locale($i18n.locale)
                      .format("h:mm a")
                  }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group :label="$t('field.channel')">
            <b-form-radio-group
              class="button-checkbox"
              v-model="data.post"
              name="channel"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio
                :value="channel.post"
                v-for="channel in channels"
                :key="`channel-${channel.id}`"
                buttons
              >
                <span>
                  {{ channel.post }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group :label="$t('field.number')">
            <b-form-radio-group
              class="button-checkbox"
              v-model="data.numberType"
              name="number"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio
                :value="numberType.id"
                v-for="numberType in numberTypes"
                :key="`nunber-${numberType.id}`"
                buttons
              >
                <span>
                  {{ $t(numberType.label) }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col lg="6" align-self="end" v-if="$can('read', 'live-sell-out')">
          <b-form-group :label="$t('field.type')">
            <b-form-radio-group
              class="button-checkbox"
              v-model="data.type"
              name="number"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio
                :value="type.id"
                v-for="type in types"
                :key="`nunber-${type.id}`"
                buttons
              >
                <span>
                  {{ $t(type.label) }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col lg="6" align-self="end">
          <b-input-group class="input-group-merge mt-1 mb-1">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              type="text"
              :placeholder="$t('general.search')"
              v-model="data.search"
            />
          </b-input-group>
        </b-col>
      </b-row>

      <hr />
      <div v-if="typeId == 1">
        <!-- Filter by user -->
        <b-row class="betting-form-container">
          <b-col md="6">
            <b-form-group :label="$t(`field.type`)">
              <b-form-radio-group
                class="button-checkbox"
                :ref="`type`"
                :id="`type`"
                v-model="data.typeId"
                buttons
                button-variant="outline-primary"
              >
                <b-form-radio
                  :value="option.value"
                  v-for="option in userTypes"
                  :key="option.value"
                >
                  <span>
                    {{ $t(option.text) }}
                  </span>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="data.typeId != 1">
            <b-form-group :label="$t(getUserLabel())">
              <n-async-single-select
                :key="`filter-user-${data.typeId}`"
                :ref="`user`"
                v-model="data.userId"
                :name="`user`"
                :init-options="data.userId"
                :repository="getUserRepository()"
                :selection-key="`id`"
                :selection-label="`username`"
                :clearable="true"
              ></n-async-single-select>
            </b-form-group>
          </b-col>
          <b-col align-self="end">
            <b-button
              variant="primary"
              block
              class="mt-1 mb-1"
              @click="listenBetData"
            >
              {{ $t("button.filter") }}
            </b-button>
          </b-col>
        </b-row>
        <hr />
      </div>

      <b-row align-h="center">
        <b-col
          :class="{
            'bet-list-col': data.post == 'ALL',
            'mt-2': data.post == 'ALL' || data.post == bet.post,
          }"
          v-for="(bet, i) in betList"
          :key="`bet-list-${i}`"
          :cols="data.post == 'ALL' ? 0 : 12"
        >
          <div v-if="data.post == 'ALL' || data.post == bet.post">
            <b-input-group class="input-group-merge mt-1 mb-1">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                type="text"
                :placeholder="$t('general.search')"
                v-model="bet.search"
              />
            </b-input-group>

            <h3 class="text-center">
              {{ bet.post }}
            </h3>
            <div v-if="data.type == 'bet'">
              <h6 class="text-center" v-if="bet.maxNumberBets.length">
                {{ $t("general.topBet") }}
              </h6>
              <b-row>
                <b-col
                  cols="12"
                  :md="data.post == 'ALL' ? 12 : 4"
                  v-for="betting in bet.maxNumberBets"
                  :key="`max-betting-${betting.number}-${betting.post}`"
                  :class="{
                    'p-0': data.search && !betting.number.includes(data.search),
                  }"
                >
                  <div :style="{ backgroundColor: betting.color }">
                    <div
                      class="result-group"
                      :class="{
                        animate: betting.color,
                      }"
                      v-if="
                        (!data.search ||
                          betting.number.includes(data.search)) &&
                        (!bet.search || betting.number.includes(bet.search))
                      "
                    >
                      <div class="d-flex">
                        <div
                          class="d-flex align-self-center number-header text-center"
                        >
                          <h5 class="number">
                            {{ betting.number }}
                          </h5>
                        </div>
                        <div class="number-value">
                          <h5
                            class="number"
                            :class="betting.amount > 0 ? `text-positive` : ``"
                          >
                            {{ betting.amount | money(betting.ccy) }}
                          </h5>
                          <h5
                            class="number"
                            :class="
                              betting.estWinAmount > 0 ? `text-danger` : ``
                            "
                          >
                            {{ betting.estWinAmount | money(betting.ccy) }}
                          </h5>
                          <h5
                            class="number"
                            :class="
                              betting.estTotalWl > 0
                                ? `text-positive`
                                : `text-negative`
                            "
                          >
                            {{ betting.estTotalWl | money(betting.ccy) }}
                          </h5>
                        </div>
                        <div class="d-flex align-self-center number-action">
                          <n-button-loading
                            size="sm"
                            variant="danger"
                            :loading="loading"
                            @submit="blockNumber(betting, bet.post)"
                            v-if="!betting.isBlock"
                          >
                            <feather-icon icon="XIcon" />
                          </n-button-loading>
                          <n-button-loading
                            size="sm"
                            variant="success"
                            :loading="loading"
                            @submit="allowNumber(betting, bet.post)"
                            v-else
                          >
                            <feather-icon icon="CheckIcon" />
                          </n-button-loading>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <hr v-if="bet.maxNumberBets.length" />
              <h6 class="text-center" v-if="bet.numberBets.length">
                {{ $t("field.bet") }} (<span
                  v-html="countBet(bet.numberBets)"
                ></span
                >)
              </h6>
              <b-row>
                <b-col
                  :cols="data.post == 'ALL' ? 12 : 'auto'"
                  v-for="betting in bet.numberBets"
                  :key="`betting-${betting.number}-${betting.post}`"
                  :class="{
                    'p-0': data.search && !betting.number.includes(data.search),
                  }"
                >
                  <div :style="{ backgroundColor: betting.color }">
                    <div
                      class="result-group"
                      :class="{
                        animate: betting.color,
                      }"
                      v-if="
                        (!data.search ||
                          betting.number.includes(data.search)) &&
                        (!bet.search || betting.number.includes(bet.search))
                      "
                    >
                      <div class="d-flex">
                        <div
                          class="d-flex align-self-center number-header text-center"
                        >
                          <h5 class="number">
                            {{ betting.number }}
                          </h5>
                        </div>
                        <div class="number-value">
                          <h5
                            class="number"
                            :class="betting.amount > 0 ? `text-positive` : ``"
                          >
                            {{ betting.amount | money(betting.ccy) }}
                          </h5>
                          <h5
                            class="number"
                            :class="
                              betting.estWinAmount > 0 ? `text-danger` : ``
                            "
                          >
                            {{ betting.estWinAmount | money(betting.ccy) }}
                          </h5>
                          <h5
                            class="number"
                            :class="
                              betting.estTotalWl > 0
                                ? `text-positive`
                                : `text-negative`
                            "
                          >
                            {{ betting.estTotalWl | money(betting.ccy) }}
                          </h5>
                        </div>
                        <div class="d-flex align-self-center number-action">
                          <n-button-loading
                            size="sm"
                            variant="danger"
                            :loading="loading"
                            @submit="blockNumber(betting, bet.post)"
                            v-if="!betting.isBlock"
                          >
                            <feather-icon icon="XIcon" />
                          </n-button-loading>
                          <n-button-loading
                            size="sm"
                            variant="success"
                            :loading="loading"
                            @submit="allowNumber(betting, bet.post)"
                            v-else
                          >
                            <feather-icon icon="CheckIcon" />
                          </n-button-loading>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>

            <h6
              class="text-center"
              v-if="bet.sellOutNumbers.length && data.type == 'sellout'"
            >
              {{ $t("field.sellOut") }}
            </h6>
            <b-row v-if="data.type == 'sellout'">
              <b-col
                :cols="data.post == 'ALL' ? 12 : 'auto'"
                v-for="betting in bet.sellOutNumbers"
                :key="`sell-out-${betting.number}-${betting.post}`"
                :class="{
                  'p-0': data.search && !betting.number.includes(data.search),
                }"
              >
                <div :style="{ backgroundColor: betting.color }">
                  <div
                    class="result-group"
                    :class="{
                      animate: betting.color,
                    }"
                    v-if="!data.search || betting.number.includes(data.search)"
                  >
                    <div class="d-flex">
                      <div
                        class="d-flex align-self-center number-header text-center"
                      >
                        <h5 class="number">
                          {{ betting.number }}
                        </h5>
                      </div>
                      <div class="number-value">
                        <h5
                          class="number"
                          :class="betting.amount > 0 ? `text-positive` : ``"
                        >
                          {{ betting.amount | money(betting.ccy) }}
                        </h5>
                        <h5
                          class="number"
                          :class="betting.estWinAmount > 0 ? `text-danger` : ``"
                        >
                          {{ betting.estWinAmount | money(betting.ccy) }}
                        </h5>
                        <h5
                          class="number"
                          :class="
                            betting.estTotalWl > 0
                              ? `text-positive`
                              : `text-negative`
                          "
                        >
                          {{ betting.estTotalWl | money(betting.ccy) }}
                        </h5>
                        <h5 class="number text-dark">
                          {{ betting.sellOutAmount | money(betting.ccy) }}
                        </h5>
                      </div>
                      <div class="d-flex align-self-center number-action">
                        <n-button-loading
                          size="sm"
                          variant="danger"
                          :loading="loading"
                          @submit="blockNumber(betting, bet.post)"
                          v-if="!betting.isBlock"
                        >
                          <feather-icon icon="XIcon" />
                        </n-button-loading>
                        <n-button-loading
                          size="sm"
                          variant="success"
                          :loading="loading"
                          @submit="allowNumber(betting, bet.post)"
                          v-else
                        >
                          <feather-icon icon="CheckIcon" />
                        </n-button-loading>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import NButtonLoading from "@/components/NButtonLoading";
import moment from "moment";
import Repository from "@/repositories/RepositoryFactory";
import db from "@/libs/firestore-db";
import { camelizeKeys } from "humps";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";

const LotteryRepository = Repository.get("lottery");
const BlockNumberRepository = Repository.get("blockNumber");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BSpinner,
    NButtonLoading,
    NAsyncSingleSelect,
  },
  computed: {
    userData: function () {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
    typeId() {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId;
      }
      return this.userData?.typeId;
    },
  },
  data() {
    return {
      loading: false,
      refreshLoading: false,
      data: {
        lotteryId: Number(this.$route.query.lotteryId) || null,
        lotteryTimeId: Number(this.$route.query.lotteryTimeId) || null,
        post: this.$route.query.post || "ALL",
        numberType: Number(this.$route.query.numberType) || 2,
        type: this.$route.query.type || "bet",
        typeId: Number(this.$route.query.typeId) || 1,
        userId: Number(this.$route.query.userId) || null,
        search: this.$route.query.search || "",
      },
      lotteries: [],
      lotteryTimes: [],
      channels: [],
      numberTypes: [
        {
          id: 2,
          label: "field.2DigitNumber",
        },
        {
          id: 3,
          label: "field.3DigitNumber",
        },
      ],
      types: [
        {
          id: "bet",
          label: "field.bet",
        },
        {
          id: "sellout",
          label: "field.sellOut",
        },
      ],
      lotteryType: "lottery-vn",
      userTypes: [
        {
          value: 1,
          text: "field.all",
        },
        {
          value: 2,
          text: "breadcrumb.company",
        },
        {
          value: 3,
          text: "breadcrumb.senior",
        },
        {
          value: 4,
          text: "breadcrumb.master",
        },
        {
          value: 5,
          text: "breadcrumb.agent",
        },
        // {
        //   value: 6,
        //   text: "breadcrumb.player",
        // },
      ],

      betList: [],

      maxNumberBets: [],
      bettings: [],
      listenLiveBet: null,

      moment,
    };
  },
  watch: {
    "data.lotteryId"(value) {
      if (value) {
        this.data.lotteryTimeId = null;
        this.getLotteryTime(value);
        this.betList = [];
        this.listenBetData();
      }
    },
    "data.lotteryTimeId"(value) {
      if (value) {
        this.data.post = null;
        this.getLotteryChannel(value);
        this.betList = [];
        this.listenBetData();
      }
    },
    "data.post"(value) {
      if (value) {
        this.betList = [];
        this.listenBetData();
      }
    },
    "data.numberType"(value) {
      if (value) {
        this.betList = [];
        this.listenBetData();
      }
    },
    "data.typeId"() {
      this.data.userId = null;
    },
  },
  mounted() {
    this.getLottery();
    if (this.typeId == 1 && this.data.typeId != 1 && this.data.userId) {
      this.$refs.user.loadOption();
    }
  },
  beforeDestroy() {
    this.listenLiveBet && this.listenLiveBet();
  },
  methods: {
    countBet(items) {
      let betData = items.filter((item) => item.amount > 0);
      return `<span class="text-success">${
        betData.length
      }</span> | <span class="text-danger">${
        items.length - betData.length
      }</span>`;
    },
    getUserLabel() {
      if (this.data.typeId == 2) {
        return `breadcrumb.company`;
      } else if (this.data.typeId == 3) {
        return `breadcrumb.senior`;
      } else if (this.data.typeId == 4) {
        return `breadcrumb.master`;
      } else if (this.data.typeId == 5) {
        return `breadcrumb.agent`;
      }

      return `breadcrumb.player`;
    },
    getUserRepository() {
      if (this.data.typeId == 2) {
        return `company`;
      } else if (this.data.typeId == 3) {
        return `senior`;
      } else if (this.data.typeId == 4) {
        return `master`;
      } else if (this.data.typeId == 5) {
        return `agent`;
      }

      return `player`;
    },
    updateQuerySting() {
      this.$router.replace({ query: this.data });
    },
    blockNumber(betting, post) {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterDelete"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "md",
          okVariant: "danger",
          okTitle: this.$t("button.blockNumberValue", {
            number: betting.number,
          }),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            BlockNumberRepository.create({
              lotteryId: this.data.lotteryId,
              lotteryTimeId: this.data.lotteryTimeId,
              post: post,
              date: moment().format("YYYY-MM-DD"),
              number: betting.number,
            })
              .then((response) => {
                this.loading = false;
              })
              .catch((error) => {
                this.loading = false;
              });
          }
        });
    },
    allowNumber(betting, post) {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterDelete"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "md",
          okVariant: "success",
          okTitle: this.$t("button.allowNumberValue", {
            number: betting.number,
          }),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            BlockNumberRepository.unblock({
              lotteryId: this.data.lotteryId,
              lotteryTimeId: this.data.lotteryTimeId,
              post: post,
              number: betting.number,
            })
              .then((response) => {
                this.loading = false;
              })
              .catch((error) => {
                this.loading = false;
              });
          }
        });
    },
    getLottery() {
      LotteryRepository.listActive().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.lotteries = [...data];
          if (!this.data.lotteryId && this.lotteries.length) {
            this.data.lotteryId = this.lotteries[0].id;
          } else {
            this.getLotteryTime(this.data.lotteryId);
          }
        }
      });
    },
    getLotteryTime(lottery) {
      const index = this.lotteries.findIndex((element) => {
        return element.id == lottery;
      });

      if (index != -1) {
        this.lotteryType = this.lotteries[index].type;
        let times = this.lotteries[index].times;
        this.lotteryTimes = [...times];

        if (!this.data.lotteryTimeId && this.lotteryTimes) {
          this.data.lotteryTimeId = this.lotteryTimes[0].id;
        } else {
          this.getLotteryChannel(this.data.lotteryTimeId);
        }
      }
    },
    getLotteryChannel(time) {
      const index = this.lotteryTimes.findIndex((element) => {
        return element.id == time;
      });

      if (index != -1) {
        this.channels = [
          {
            id: 0,
            post: "ALL",
          },
          ...this.lotteryTimes[index].posts,
        ];
        if (!this.data.post && this.channels.length) {
          this.data.post = this.channels[0].post;
        }
        if (this.data.numberType) {
          this.betList = [];
          this.listenBetData();
        }
      }
    },

    listenBetData() {
      if (
        !this.data.lotteryId ||
        !this.data.lotteryTimeId ||
        !this.data.numberType
      ) {
        return;
      }

      let userId = this.userData.id;
      if (this.typeId == 1) {
        if (this.data.typeId == 1 || !this.data.userId) {
          userId = 1;
        } else {
          userId = this.data.userId;
        }
      }

      this.betList = [];
      this.updateQuerySting();
      this.listenLiveBet && this.listenLiveBet();
      this.listenLiveBet = db
        .collection(`livebet`)
        .doc(
          `${userId}-${this.data.lotteryId}-${this.data.lotteryTimeId}-${this.data.numberType}`
        )
        .onSnapshot((response) => {
          const data = response.get("data");
          if (data) {
            let bettings = camelizeKeys(JSON.parse(data));
            this.betList = [...bettings];
          }
        });
    },
  },
  setup() {},
};
</script>